@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import "./items.css";
body {
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    background: #FAFAFA;
}

.rct-item-content {
    border: dotted 1px deepskyblue;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    border-bottom: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
    background-color: #ffffff !important;
}
.react-calendar-timeline .rct-header-root{
    background: white !important;
    color: #000 !important;
    text-align: center;
    padding-top: 1rem;
    position: -webkit-sticky; /* Pour Safari */
    position: sticky !important;
    top: 0; /* L'élément restera collé en haut de son parent */
    /* border: none !important; */
    z-index: 999; /* Assurez-vous qu'il est au-dessus des autres éléments */

}

.react-calendar-timeline .rct-dateHeader-primary{
    background: white !important;
    color: burlywood !important;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1em !important;
    font-weight: bold;

    border: none !important;
    /* border-bottom: #000; */
}
.react-calendar-timeline .rct-dateHeader-primary span {
    padding-bottom: 20px;
}

.react-calendar-timeline .rct-dateHeader{
    background: white !important;
    width: 26px !important;
    text-align: center !important;
    justify-content: unset !important;
}

.react-calendar-timeline .rct-calendar-header{
    border: none !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border-bottom: none;
    font-weight: bold;
}

.react-calendar-timeline .rct-header .rct-label {
    border-bottom: none
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
    border-color: #ccc  !important;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
    border-left: 1px solid #eeeeee87;
}

.react-calendar-timeline .rct-header .rct-label-group {
    font-family: 'Work Sans', sans-serif;
    background: white !important;
    color: #000 !important;
    font-size: 1rem;
}

.react-calendar-timeline .rct-sidebar-header {
    background: white !important;
    color: #000 !important;
    text-align: center;
    padding-top: 1rem;
    border: none;
    border-right: solid 2px #ccc !important;
}

.react-calendar-timeline{
    overflow: auto; /* ou overflow: scroll; */
    /* Ajoutez une hauteur ou une largeur à votre choix pour que la zone défile */
    height: 60vh; /* 60% of the viewport height */
    
    text-align: center !important;
    justify-content: unset !important;
}

/* Pour les navigateurs basés sur les standards */
.react-calendar-timeline {
    scrollbar-width: none; /* Pour Firefox */
  }
  
  /* Pour les navigateurs basés sur WebKit */
  .react-calendar-timeline::-webkit-scrollbar {
    display: none; /* Pour Chrome, Safari, et autres */
  }
  